import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Button, notification } from "antd";
import React, { useEffect, useState } from "react";
import { CLIENT_ID } from "../Config/ConfigPaypal";
import useCheckout from "./useHooks/useCheckout";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

import "./styles/checkout.css";

import { useNavigate } from "react-router-dom";

const Checkout = ({ checkPlaceOrder, totalCost, deliveryInfo, label }) => {
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState("");

  const [orderID, setOrderID] = useState(false);
  const navigate = useNavigate();
  const { response, error, postData } = useCheckout();

  const showSuccessNotification = () => {
    notification.open({
      message: "Success",
      description:
        "We received your purchase request; we'll be in touch shortly!",
      icon: <CheckCircleFilled style={{ color: "#0B577E" }} />,
      placement: "top",
    });
  };

  const showErrorNotification = () => {
    notification.open({
      message: "Failed",
      description: "Something went wrong. Please try again.",
      icon: <CloseCircleFilled style={{ color: "red" }} />,
      placement: "top",
    });
  };

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Prayer Space",
            amount: {
              currency_code: "USD",
              value: totalCost,
            },
          },
        ],
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  const handlePost = () => {
    const {
      firstName,
      lastName,
      email,
      address,
      city,
      state,
      province,
      postalCode,
      contact,
    } = deliveryInfo;
    const data = {
      userFirstName: firstName,
      userLastName: lastName,
      userEmail: email,
      userContact: contact,
      userAddress: address,
      userCity: city,
      state: state,
      postalCode: postalCode,
      purchasedSpotLabels: label,
      totalAmount: totalCost,
      paymentMethod: "Credit Card",
      paymentStatus: success,
    };

    const url = "https://api.iioc.com/api/purchase/checkout";

    postData(url, data);
  };

  const onError = (error) => {
    showErrorNotification();
    localStorage.removeItem("selectedSeats");
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order
      .capture()
      .then(function (details) {
        const { payer } = details;

        setSuccess("success");
      })
      .catch(function (error) {
        console.error("An error occurred during payment capture:", error);

        showErrorNotification();
        localStorage.removeItem("selectedSeats");
      });
  };

  useEffect(() => {
    if (success === "success") {
      handlePost();
      navigate("/success");
      // showSuccessNotification();

      localStorage.removeItem("selectedSeats");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <div className="checkout-parent">
      <PayPalScriptProvider options={{ "client-id": CLIENT_ID }}>
        <button
          className={`sc-bdvvtL jPQkWg sc-iboAsT sc-bcCSHH hEyZIb cRkVpC  noFocus place-order ${
            checkPlaceOrder() ? "" : "disabled"
          } `}
          onClick={() => setShow(true)}
        >
          Proceed To Pay
        </button>

        <br />
        {show && checkPlaceOrder() ? (
          <PayPalButtons
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
          />
        ) : null}
      </PayPalScriptProvider>
    </div>
  );
};

export default Checkout;
